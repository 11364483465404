import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizedComponent } from 'app/presentation/base/localized.component';
import { DYNAMIC_PRICING_POPUP_KEY, SHIPPING_INFO_URL } from '../../constants';
import { LocalStorageService } from '../../services/local-storage.service';
import { LoaderComponent } from '../loader/loader.component';

@Component({
  selector: 'app-dynamic-pricing-popup',
  templateUrl: './dynamic-pricing-popup.component.html',
  styleUrls: ['./dynamic-pricing-popup.component.scss'],
  standalone: true,
  imports: [MatCheckboxModule, CommonModule, FormsModule, TranslateModule, LoaderComponent],
})
export class DynamicPricingPopupComponent extends LocalizedComponent {
  public isLoading = false;

  public dynamicPricingSectionUrl = `${SHIPPING_INFO_URL}#dynamic-pricing`;

  constructor(
    public _dialogRef: MatDialogRef<DynamicPricingPopupComponent>,
    private localStorageService: LocalStorageService,
  ) {
    super();
  }

  public closeDialog(): void {
    this.hideDynamicPricingPopup();
    this._dialogRef.close();
  }

  public hideDynamicPricingPopup(): void {
    this.localStorageService.setStorage(DYNAMIC_PRICING_POPUP_KEY, true);
  }
}
