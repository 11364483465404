import { NgClass, NgFor, NgIf } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MoengageService } from 'app/presentation/shared/services/mo-engage.service';
import { Subscription } from 'rxjs';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { ENGLISH_LANGUAGE } from '../../constants/country-language-codes-mapping.constants';
import { CommercialCategoryTreeNode } from '../../interfaces/commercial-categories.interface';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  standalone: true,
  imports: [RouterLink, NgFor, NgClass, TranslateModule, NgIf],
})
export class BreadcrumbsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() categoryHierachy: CommercialCategoryTreeNode[];

  public navigationHeirarchyArray: CommercialCategoryTreeNode[];

  public isEnglishLanguageSelected = false;

  private _languageChangeSubscription: Subscription;

  @Output() emittedCategoryId = new EventEmitter<{ id: string }>();

  @Input() isNewPreOrderEnabled: Boolean;

  constructor(
    @Inject(appUrlsConstantsInjectionToken) public appURLs: { [url: string]: string },
    private _translateService: TranslateService,
    private _router: Router,
    private _moEngageService: MoengageService,
  ) {}

  ngOnInit(): void {
    this.isEnglishLanguageSelected = this._translateService.currentLang === ENGLISH_LANGUAGE;
    this._languageChangeSubscription = this._translateService.onLangChange.subscribe({
      next: ({ lang }: { lang: string }) => {
        this.isEnglishLanguageSelected = lang === ENGLISH_LANGUAGE;
      },
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.categoryHierachy) {
      this.navigationHeirarchyArray = [...this.categoryHierachy];
      this.navigationHeirarchyArray.pop();
      this.navigationHeirarchyArray.reverse();
    }
  }

  onCategoryClicked(category: any): void {
    this._moEngageService.trackEvent('User_Clicked_Category', {
      category: category.name.englishName,
      Click_Type: 'Category',
    });

    if (this.isNewPreOrderEnabled) {
      this.emittedCategoryId.emit({ id: category.key });
    } else {
      this._router.navigate(['/', 'products', 'category', category.key]);
    }
  }

  ngOnDestroy(): void {
    this._languageChangeSubscription.unsubscribe();
  }
}
