<div class="circular-progress">
  <svg viewBox="0 0 36 36" class="circular-chart">
    <path
      class="circle-bg"
      d="M18 2.0845
             a 15.9155 15.9155 0 0 1 0 31.831
             a 15.9155 15.9155 0 0 1 0 -31.831"
    />
    <path
      class="circle"
      [attr.stroke-dasharray]="percentage + ', 100'"
      d="M18 2.0845
             a 15.9155 15.9155 0 0 1 0 31.831
             a 15.9155 15.9155 0 0 1 0 -31.831"
    />
  </svg>
</div>
