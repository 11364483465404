import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-circular-progress-bar',
  templateUrl: './circular-progress-bar.component.html',
  styleUrls: ['./circular-progress-bar.component.scss'],
  standalone: true,
})
export class CircularProgressBarComponent {
  @Input() percentage = 0;
}
