<loader [loading]="isLoading" class="loader"></loader>
<div class="dynamic-pricing-container" [dir]="direction">
  <div class="logo-container">
    <img src="assets/img/dynamic-price-products.svg" />
    <button (click)="closeDialog()" class="dialog-close-button">
      <span>&times;</span>
    </button>
  </div>

  <p class="subtitle caption1--regular">
    {{ trans('PRODUCTS_PAGE.DYNAMIC_PRICING_POPUP.SUB_TITLE') }}
  </p>

  <p class="title heading2--bold">{{ trans('PRODUCTS_PAGE.DYNAMIC_PRICING_POPUP.TITLE') }}</p>

  <p class="description caption1--regular">
    {{ trans('PRODUCTS_PAGE.DYNAMIC_PRICING_POPUP.DESCRIPTION') }}
  </p>

  <a
    [href]="dynamicPricingSectionUrl"
    class="cta body2--bold"
    target="_blank"
    (click)="hideDynamicPricingPopup()"
  >
    {{ trans('PRODUCTS_PAGE.DYNAMIC_PRICING_POPUP.CTA') }}
  </a>

  <div class="hint-container">
    <img src="assets/img/ksa-flag.svg" />
    <p class="caption2--regular text-center">
      {{ trans('PRODUCTS_PAGE.DYNAMIC_PRICING_POPUP.HINT') }}
    </p>
  </div>
</div>
